<template>
  <div class="page-wrapper">

    <section class="hero">
      <div class="wrap">
        <img src="@/assets/img/icon-voile.svg" alt="Icon Sailitics" class="icon" data-inview="fadeInUp" data-delay="200">
        <h1 class="title" data-inview="fadeInUp" data-delay="300">Vos <span class="bold">données</span> marketing au coeur <br>de vos <span class="bold">décisions</span> stratégiques.</h1>
      </div>
      <img src="@/assets/img/arrow-down.svg" alt="Arrow down" class="arrow">
      <img src="@/assets/img/hero-lines-left.svg" alt="Lines" class="lines_left">
      <img src="@/assets/img/hero-lines-right.svg" alt="Lines" class="lines_right">
    </section>

    <section class="scroll_logo">
      <h2 class="title" data-inview="fadeInUp" data-delay="200">Entreprise spécialisée dans la collecte,<br> l’accessibilité et l’analyse de données multi-plateformes.</h2>
      <div class="logo_wrap" data-inview="fadeInUp" data-delay="300">
        <div class="line line1">
          <div class="logo"><img src="@/assets/img/logos/double-click.jpg" alt="Double Click logo"></div>
          <div class="logo"><img src="@/assets/img/logos/active-campaign.jpg" alt="Active Campaign logo"></div>
          <div class="logo"><img src="@/assets/img/logos/zapier.jpg" alt="Zapier logo"></div>
          <div class="logo"><img src="@/assets/img/logos/dv360.jpg" alt="DV360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/funnel.jpg" alt="Funnel logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-ads.jpg" alt="Google ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-4.jpg" alt="Google Analytics 4 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-data-studio.jpg" alt="Google Data Studio logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-cloud-platform.jpg" alt="Google Cloud Platform logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-shopping.jpg" alt="Google Shopping logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-tag-manager.jpg" alt="Google Tag Manager logo"></div>
          <div class="logo"><img src="@/assets/img/logos/hubspot.jpg" alt="Hubspot logo"></div>
          <div class="logo"><img src="@/assets/img/logos/klaviyo.jpg" alt="Klaviyo logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics.jpg" alt="Google Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-360.jpg" alt="Google Analytics 360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-analytics.jpg" alt="Power Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/snapchat-ads.jpg" alt="Snapchat ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/make.jpg" alt="Make logo"></div>
          <div class="logo"><img src="@/assets/img/logos/omnisend.jpg" alt="Omnisend logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-bi.jpg" alt="Power BI logo"></div>
          <div class="logo"><img src="@/assets/img/logos/segment.jpg" alt="Segment logo"></div>
          <div class="logo"><img src="@/assets/img/logos/supermetrics.jpg" alt="Supermetrics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/tableau.jpg" alt="Tableau logo"></div>
          <div class="logo"><img src="@/assets/img/logos/wicked-report.jpg" alt="Wicked report logo"></div>
          <div class="logo"><img src="@/assets/img/logos/double-click.jpg" alt="Double Click logo"></div>
          <div class="logo"><img src="@/assets/img/logos/active-campaign.jpg" alt="Active Campaign logo"></div>
          <div class="logo"><img src="@/assets/img/logos/zapier.jpg" alt="Zapier logo"></div>
          <div class="logo"><img src="@/assets/img/logos/dv360.jpg" alt="DV360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/funnel.jpg" alt="Funnel logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-ads.jpg" alt="Google ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-4.jpg" alt="Google Analytics 4 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-data-studio.jpg" alt="Google Data Studio logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-cloud-platform.jpg" alt="Google Cloud Platform logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-shopping.jpg" alt="Google Shopping logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-tag-manager.jpg" alt="Google Tag Manager logo"></div>
          <div class="logo"><img src="@/assets/img/logos/hubspot.jpg" alt="Hubspot logo"></div>
          <div class="logo"><img src="@/assets/img/logos/klaviyo.jpg" alt="Klaviyo logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics.jpg" alt="Google Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-360.jpg" alt="Google Analytics 360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-analytics.jpg" alt="Power Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/snapchat-ads.jpg" alt="Snapchat ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/make.jpg" alt="Make logo"></div>
          <div class="logo"><img src="@/assets/img/logos/omnisend.jpg" alt="Omnisend logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-bi.jpg" alt="Power BI logo"></div>
          <div class="logo"><img src="@/assets/img/logos/segment.jpg" alt="Segment logo"></div>
          <div class="logo"><img src="@/assets/img/logos/supermetrics.jpg" alt="Supermetrics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/tableau.jpg" alt="Tableau logo"></div>
          <div class="logo"><img src="@/assets/img/logos/wicked-report.jpg" alt="Wicked report logo"></div>
        </div>
        <div class="line line2">
          <div class="logo"><img src="@/assets/img/logos/segment.jpg" alt="Segment logo"></div>
          <div class="logo"><img src="@/assets/img/logos/make.jpg" alt="Make logo"></div>
          <div class="logo"><img src="@/assets/img/logos/hubspot.jpg" alt="Hubspot logo"></div>
          <div class="logo"><img src="@/assets/img/logos/zapier.jpg" alt="Zapier logo"></div>
          <div class="logo"><img src="@/assets/img/logos/dv360.jpg" alt="DV360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/funnel.jpg" alt="Funnel logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-ads.jpg" alt="Google ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-4.jpg" alt="Google Analytics 4 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-data-studio.jpg" alt="Google Data Studio logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-cloud-platform.jpg" alt="Google Cloud Platform logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-shopping.jpg" alt="Google Shopping logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-tag-manager.jpg" alt="Google Tag Manager logo"></div>
          <div class="logo"><img src="@/assets/img/logos/active-campaign.jpg" alt="Active Campaign logo"></div>
          <div class="logo"><img src="@/assets/img/logos/klaviyo.jpg" alt="Klaviyo logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics.jpg" alt="Google Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-360.jpg" alt="Google Analytics 360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-analytics.jpg" alt="Power Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/snapchat-ads.jpg" alt="Snapchat ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/omnisend.jpg" alt="Omnisend logo"></div>
          <div class="logo"><img src="@/assets/img/logos/double-click.jpg" alt="Double Click logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-bi.jpg" alt="Power BI logo"></div>
          <div class="logo"><img src="@/assets/img/logos/supermetrics.jpg" alt="Supermetrics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/tableau.jpg" alt="Tableau logo"></div>
          <div class="logo"><img src="@/assets/img/logos/wicked-report.jpg" alt="Wicked report logo"></div>
          <div class="logo"><img src="@/assets/img/logos/active-campaign.jpg" alt="Active Campaign logo"></div>
          <div class="logo"><img src="@/assets/img/logos/zapier.jpg" alt="Zapier logo"></div>
          <div class="logo"><img src="@/assets/img/logos/dv360.jpg" alt="DV360 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/funnel.jpg" alt="Funnel logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-ads.jpg" alt="Google ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-4.jpg" alt="Google Analytics 4 logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-cloud-platform.jpg" alt="Google Cloud Platform logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-shopping.jpg" alt="Google Shopping logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-tag-manager.jpg" alt="Google Tag Manager logo"></div>
          <div class="logo"><img src="@/assets/img/logos/hubspot.jpg" alt="Hubspot logo"></div>
          <div class="logo"><img src="@/assets/img/logos/klaviyo.jpg" alt="Klaviyo logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics.jpg" alt="Google Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-analytics.jpg" alt="Power Analytics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/snapchat-ads.jpg" alt="Snapchat ads logo"></div>
          <div class="logo"><img src="@/assets/img/logos/omnisend.jpg" alt="Omnisend logo"></div>
          <div class="logo"><img src="@/assets/img/logos/power-bi.jpg" alt="Power BI logo"></div>
          <div class="logo"><img src="@/assets/img/logos/double-click.jpg" alt="Double Click logo"></div>
          <div class="logo"><img src="@/assets/img/logos/segment.jpg" alt="Segment logo"></div>
          <div class="logo"><img src="@/assets/img/logos/supermetrics.jpg" alt="Supermetrics logo"></div>
          <div class="logo"><img src="@/assets/img/logos/tableau.jpg" alt="Tableau logo"></div>
          <div class="logo"><img src="@/assets/img/logos/wicked-report.jpg" alt="Wicked report logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-data-studio.jpg" alt="Google Data Studio logo"></div>
          <div class="logo"><img src="@/assets/img/logos/make.jpg" alt="Make logo"></div>
          <div class="logo"><img src="@/assets/img/logos/google-analytics-360.jpg" alt="Google Analytics 360 logo"></div>
        </div>
      </div>
    </section>

    <section class="expertises" id="expertises">
      <div class="top">
        <img src="@/assets/img/wave-top.svg" alt="wave">
      </div>
      <div class="intro">
        <div class="data">
          <h3 class="title semi" data-inview="fadeInUp" data-delay="200">Expertises</h3>
          <h2 class="title" data-inview="fadeInUp" data-delay="300">Exploitez vos<br>données marketing</h2>
        </div>
        <div class="video">
          <img src="@/assets/img/photo-temporaire.jpg" alt="">
        </div>
      </div>
      <div class="content" data-inview="fadeInUp" data-delay="200">
        <h2 class="title small"><span>Collecter</span> les données</h2>
        <p class="regular-text">Nous vous aidons à mettre en place un <span>plan mesure</span> complet qui vous permettra d'accroître votre patrimoine data et mieux comprendre le <span>comportement de vos utilisateurs</span>.</p>
        <div class="line"></div>
      </div>
      <div class="content" data-inview="fadeInUp" data-delay="200">
        <h2 class="title small"><span>Organiser et vulgariser</span> les données</h2>
        <p class="regular-text">L'accessibilité à vos données marketing est souvent manuelle, longue et complexe pour vos équipes. Nous créons des worklows uniques pour <span>automatiser ces étapes de récupération des données</span>, pour que vous puissiez vous concentrer sur ce que vous faites le mieux, faire grandir votre marque.</p>
        <div class="line"></div>
      </div>
      <div class="content" data-inview="fadeInUp" data-delay="200">
        <h2 class="title small"><span>Actionner</span> les données</h2>
        <p class="regular-text">Avec les bonnes <span>données accessibles rapidement</span>, vous gagnez en agilité et mettez en place des actions efficaces, rapides et basées sur votre patrimoine data.</p>
      </div>
    </section>

    <section class="solutions" id="solutions"> 
      <div class="top">
        <img src="@/assets/img/wave-bottom.svg" alt="wave">
      </div>
      <div class="title_wrap">
        <h3 class="title semi" data-inview="fadeInUp" data-delay="200">Solutions</h3>
        <h2 class="title" data-inview="fadeInUp" data-delay="200">Un investissement<br>pour votre marque</h2>
      </div>
      <div class="scroll">
        
        <div class="expertise" data-inview="fadeInUp" data-delay="200">
          <div class="gradient">
            <img src="@/assets/img/solution-icon-1.svg" alt="Tracking web" class="icon">
            <div class="content">
              <h4 class="title semi">Tracking web</h4>
              <img src="@/assets/img/play.svg" alt="play" class="play">
            </div>
          </div>
          <div class="data">
            <p class="regular-text">Nous mettons en place le taggage complet de vos sites web et applications mobiles.</p>
          </div>
        </div>

        <div class="expertise" data-inview="fadeInUp" data-delay="300">
          <div class="gradient">
            <img src="@/assets/img/solution-icon-2.svg" alt="Marketing Technologique" class="icon">
            <div class="content">
              <h4 class="title semi">Marketing Technologique</h4>
              <img src="@/assets/img/play.svg" alt="play" class="play">
            </div>
          </div>
          <div class="data">
            <p class="regular-text">Nous vous proposons les outils marketing les plus puissants du marché correspondant à vos besoins et à votre budget.</p>
          </div>
        </div>

        <div class="expertise" data-inview="fadeInUp" data-delay="400">
          <div class="gradient">
            <img src="@/assets/img/solution-icon-3.svg" alt="BI - Exploitation des données" class="icon">
            <div class="content">
              <h4 class="title semi">BI - Exploitation des données</h4>
              <img src="@/assets/img/play.svg" alt="play" class="play">
            </div>
          </div>
          <div class="data">
            <p class="regular-text">Nous extrayons, transformons et visualisons les données de façon accessible pour vous et vos équipes.</p>
          </div>
        </div>

        <div class="expertise">
          <div class="gradient">
            <img src="@/assets/img/solution-icon-1.svg" alt="Tracking web" class="icon">
            <div class="content">
              <h4 class="title semi">Tracking web</h4>
              <img src="@/assets/img/play.svg" alt="play" class="play">
            </div>
          </div>
          <div class="data">
            <p class="regular-text">Nous mettons en place le taggage complet de vos sites web et applications mobiles.</p>
          </div>
        </div>

        <div class="expertise">
          <div class="gradient">
            <img src="@/assets/img/solution-icon-2.svg" alt="Marketing Technologique" class="icon">
            <div class="content">
              <h4 class="title semi">Marketing Technologique</h4>
              <img src="@/assets/img/play.svg" alt="play" class="play">
            </div>
          </div>
          <div class="data">
            <p class="regular-text">Nous vous proposons les outils marketing les plus puissants du marché correspondant à vos besoins et votre budget.</p>
          </div>
        </div>

        <div class="expertise">
          <div class="gradient">
            <img src="@/assets/img/solution-icon-3.svg" alt="BI - Exploitation des données" class="icon">
            <div class="content">
              <h4 class="title semi">BI - Exploitation des données</h4>
              <img src="@/assets/img/play.svg" alt="play" class="play">
            </div>
          </div>
          <div class="data">
            <p class="regular-text">Nous extrayons, transformons et visualisons les données de façon accessible pour vous et vos équipes.</p>
          </div>
        </div>

      </div>
      <img src="@/assets/img/solution-lines.svg" alt="Lines" class="lines">
    </section>

    <section class="capitaine" id="contact">
      <div class="video_wrap">
        <h2 class="title" data-inview="fadeInUp" data-delay="200">Naviguer vos données avec<br> un capitaine d’expérience</h2>
        <video src="@/assets/video/bateau.mp4" playsinline autoplay muted loop class="video"></video>
      </div>
      <div class="contacts" data-inview="fadeInUp" data-delay="200">
        <div class="colas">
          <img src="@/assets/img/colas-de-rienzo.jpg" alt="Colas de Rienzo">
          <div class="content">
            <h2 class="title semi">Colas de Rienzo</h2>
            <p class="regular-text">Marketing numérique, analyse Web et<br>gestion des données Fondateur de Sailitics</p>
          </div>
        </div>
        <div class="contact">
          <div>
            <a href="tel:14383462793">+1 438 346-2793</a>
            <a href="mailto:colas@sailitics.com">colas@sailitics.com</a>
          </div>
          <a href="#" class="cta">Planifier un appel</a>
        </div>
      </div>
    </section>

    <section class="clients">
      <h3 class="title small" data-inview="fadeInUp" data-delay="200">Entreprises ayant navigué avec Sailitics</h3>
      <div class="logo_wrap">
        <div class="logo" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/clients/client-1.jpg" alt="Doordash">
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/clients/client-2.jpg" alt="Toyota">
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/clients/client-3.jpg" alt="Première Moisson">
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="600">
          <img src="@/assets/img/clients/client-4.jpg" alt="Croix Bleue">
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="700">
          <img src="@/assets/img/clients/client-5.jpg" alt="ITI">
        </div>
      </div>
    </section>

    <section class="form_wrap" id="book">
      <h3 class="title" data-inview="fadeInUp" data-delay="200">Discutons de vos données</h3>
      <form
        action=""
        name="contact"
        method="post"
        data-netlify="true"
        data-inview="fadeInUp" data-delay="300">
        <input type="hidden" name="form-name" value="contact" />
        <input name="firstname" type="text" placeholder="* Prénom" class="input" required>
        <input name="lastname" type="text" placeholder="* Nom" class="input" required>
        <input name="entreprise" type="text" placeholder="Entreprise" class="input">
        <input name="courriel" type="email" placeholder="* Courriel" class="input" required>
        <textarea name="objectifs" placeholder="Quels sont vos objectifs d'affaires ?" class="input textarea"></textarea>
        <input type="submit" value="Envoyer" class="submit">
      </form>
    </section>

    <footer class="footer">
      <p class="regular-text">Copyright © 2022 Sailitics.</p>
      <a href="https://jnv.dev" target="_blank" class="jnv">
        <p class="regular-text">Par</p>
        <img src="@/assets/img/jnv.svg" alt="J&V logo">
      </a>
    </footer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { gsap } from 'gsap'

export default {
  head() {
    return {
      title: {
        inner: 'Exploitez vos données'
      },
      meta: [
        {
          name: 'description',
          content: 'Analyser les bonnes données pour la prise de vos décisions.',
          id: 'desc'
        },
        { name: 'application-name', content: 'Exploitez vos données' },
        { name: 'twitter:title', content: 'Analyser les bonnes données pour la prise de vos décisions.'},
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Analyser les bonnes données pour la prise de vos décisions.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Exploitez vos données'},
        { property: 'og:site_name', content: 'Exploitez vos données' },
        {
          property: 'og:description',
          content: 'Analyser les bonnes données pour la prise de vos décisions.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://sailitics.netlify.app/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://sailitics.netlify.app/img/og.jpg'
        }
      ]
    }
  },
  watch: {
      $route: {
          handler($route) {
              
              if ($route) {

                setTimeout(() => {
                  gsap.to('.line1', { x: '-150vw', duration: 80, ease: 'none', repeat: -1 })
                  gsap.to('.line2', { x: '150vw', duration: 80, ease: 'none', repeat: -1 })
                    
                }, 155)

              }

          },
          immediate: true,
      },
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
