<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  components: { NavBar },
  methods: {
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.navbar')) {
        return
      }

      if (st >= 25) {
        document.querySelector('.navbar').classList.add('sticky')
      } else {
        document.querySelector('.navbar').classList.remove('sticky')
      }
    },
  },
  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },
}
</script>

<style lang="scss">

  @import '@/theme/variables.scss';
  @import '@/theme/typos.scss';
  @import '@/theme/header.scss';
  @import '@/theme/home.scss';
  @import '@/theme/animations-layer.scss';
  

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: $color1;
    font-family: graphie, sans-serif;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }
</style>
