<template>
  <header>
    <div class="navbar">
      <div class="logo">
        <img src="@/assets/img/logo.svg" alt="Logo Sailitics">
      </div>
      <div class="right">
        <nav>
          <a href="#expertises" v-scroll-to:-90>Expertises</a>
          <a href="#solutions" v-scroll-to:-90>Solutions</a>
          <a href="#contact" v-scroll-to:-90>Contact</a>
        </nav>
        <a href="#book" v-scroll-to:-90 class="cta">Planifier un appel</a>
        <div class="burger" @click.prevent="showMobileMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="mobile_menu" :class="{ show: menuIsOpen }">
      <div class="menu">
        <img src="@/assets/img/close.svg" class="close" alt="Close" @click.prevent="showMobileMenu">
        <nav>
          <img src="@/assets/img/logo-dark.svg" class="logo" alt="Logo Sailitics">
          <a href="#expertises" @click.prevent="showMobileMenu" v-scroll-to:-90>Expertises</a>
          <a href="#solutions" @click.prevent="showMobileMenu" v-scroll-to:-90>Solutions</a>
          <a href="#contact" @click.prevent="showMobileMenu" v-scroll-to:-90>Contact</a>
          <a href="#book" @click.prevent="showMobileMenu" v-scroll-to:-90 class="cta">Planifier un appel</a>
        </nav>
      </div>
      <div class="overlay" @click.prevent="showMobileMenu"></div>
    </div>

  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
      return {
          menuIsOpen: false,
      }
  },
  methods: {
    showMobileMenu() {
      if (this.menuIsOpen) {
        this.menuIsOpen = false
      } else {
        this.menuIsOpen = true
      }
    },
  },
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
